import { connect } from '../../common/components/runtime-context';
import { getIsMobile } from '../../common/store/basic-params/basic-params-selectors';
import { REDUCERS } from '../../common/components/runtime-context/reducers';

export default function withDeviceType(WrappedComponent) {
  const mapRuntimeToProps = (state, ownProps, actions, host) => {
    const isMobile = getIsMobile(state);
    return {
      isMobile,
      isDesktop: !isMobile,
    };
  };

  return connect(mapRuntimeToProps, [REDUCERS.BASIC_PARAMS])(WrappedComponent);
}
