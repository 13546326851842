import { scrollBy } from './scroll-by';
import { getElementsPositionToRootWindow } from './get-elements-position';

const BG_FADE_OUT_CLASS = 'bgTransition';
const FOCUSED_COMMENT_CLASS = 'focused-comment-background-color';

export const scrollToComment = (
  commentId,
  { tries = 20, isReply = false, pinTime = 1500, shouldPinDown = true } = {},
) => {
  const comment = document.getElementById(commentId);
  if (comment) {
    const scroll = () => {
      getElementsPositionToRootWindow(comment, { quarter: true }, y => {
        scrollBy(0, y, false);
      });
    };

    scroll();
    comment.focus();
    shouldPinDown && pinDownTheComment({ pinTime, scroll });
    
    !isReply && comment.classList.add(FOCUSED_COMMENT_CLASS);
    comment.parentElement.classList.add(FOCUSED_COMMENT_CLASS);
    setTimeout(() => {
      !isReply && comment.classList.add(BG_FADE_OUT_CLASS);
      comment.parentElement.classList.add(BG_FADE_OUT_CLASS);
      !isReply && comment.classList.remove(FOCUSED_COMMENT_CLASS);
      comment.parentElement.classList.remove(FOCUSED_COMMENT_CLASS);
    }, pinTime);
  } else if (tries > 0) {
    // we don't know when render is done (can be heavy page), so we retry few times
    setTimeout(() => scrollToComment(commentId, tries - 1), tries * 50);
  }
};

function pinDownTheComment({ pinTime, scroll }) {
  // quick hacky fix for issue that comment gets focused and then is pushed out of screen by images..
  // doesn't help on slow networks though
  const start = Date.now();
  const pin = () => {
    scroll();
    if (Date.now() - start < pinTime) {
      requestAnimationFrame(pin);
    }
  };

  requestAnimationFrame(pin);
}
